<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Stock Produksi</h2>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <div class="col-sm-label">
                        <label class="form-label font-weight-bold">Gudang</label>
                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                    </div>
                    <div class="col-sm-content">
                        <CSelect id="StorageId" class="font-weight-bold " :options="StorageIdData" :value.sync="StorageId" />
                    </div>
                    <div class="col-sm-label">
                        <label class="form-label font-weight-bold">Tipe Detail</label>
                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                    </div>
                    <div class="col-sm-content">
                        <v-select id="TypeDetail" class="pb-3" :options="TypeDetailData" v-model="TypeDetail" />
                    </div>
                </CRow>
                <hr>
                <CRow >
                    <CCol class="sm-3">
                        <button type="button" class= "btn btn-primary" v-on:click="exportExcel">
                            <i class="fa fa-file-excel-o"></i> Excel
                        </button>
                    </CCol>
                </CRow>
                <hr>
                <stock-grid ref="grid" :key="gridReload" v-bind:storage-id="this.StorageId" v-bind:type-detail="this.TypeDetail"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import ProductionStockService from '../ProductionStock/Script/ProductionStockServices.js';
import ProductionStockGrid from '../ProductionStock/Grid/ProductionStockGrid.vue';
import moment from 'moment';

export default {
    name: 'ProductionStock',
    components: {
        'stock-grid': ProductionStockGrid,
    },
    async mounted () {
        this.StorageIdData = await ProductionStockService.getStorageId();
        this.StorageId = this.StorageIdData[0].value;
        
        this.TypeDetailData = await ProductionStockService.readTypeDetailQuery();
        this.TypeDetail = null;
    },
    data(){
        return{
            StorageId: '',
            StorageIdData : [],
            
            TypeDetail: null,
            TypeDetailData: [],
            gridReload: 0,
        }
    },
    methods: {
        reload(){
            this.gridReload++;
        },
        async exportExcel() {
            var XLSX = require("xlsx");
            var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

            var filename = 'StockProduksi_' + dateNow + '.xlsx';
            var data = await this.$refs.grid.excel(this.StorageIdData);
            var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
            ws['!cols'] = ProductionStockService.ProductionStockExcelHeaderSize();
            
            var wb = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(wb, ws);
            XLSX.writeFile(wb, filename);
        }
    }
}
</script>

<style scoped>
</style>